<template>
    <div>
        <HeaderBar />
        <Header steps="true" stepNumb="2" />
        <Steps step_a="2" />
        <div class="background-form background-grey">
            <div class="container">
                <div class="bloque-informacion-basica form-paso2" id="paso2">
                    <div class="row align-items-start">
                        <div class="informacion-basica-titulo col">
                            <h2 class="informacion-basica-texto">{{$t('academicResults.title')}}</h2>
                            <div class="divider-wrap ">
                                <div class="divider-middle mx-auto"></div>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="saveAndContinue();scroll2error();" novalidate>



                        <div class="row align-items-start">


                            <h2 class="bloque-info bloque-info-last col-12 text-center">{{$t('basicInformation.sectionThree.title')}}</h2>
                            <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                            <div class="form-group position-relative">
                                <label for="colegio-ahora" class="form-required">{{$t('basicInformation.sectionThree.school')}}</label>
                                <div>
                                <input v-model="student.current_school.name" class="form-control" :class="{'input-blue':student.current_school.name!==null}" id="colegio-ahora" rows="1" autocomplete="off">
                                <div v-if="loadingSchool" class="loading-spinner"></div>
                                </div>
                                <div v-if="dropdownSchool" id="desplegable-colegios">
                                <a v-for="(school, index) in schools" :key="index" @click="selectSchool(school)">
                                    {{school.name}}
                                </a>
                                </div>

                            <div class="tooltip-error" v-if="formchecked && v$.student.current_school.name.required.$invalid">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>
                                <div class="tooltip-error" v-if=" formchecked && v$.student.current_school.name.maxLength.$invalid">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                                </div>


                            </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-3">
                            <div class="form-group">
                                <label for="colegio-pais" class="form-required">{{$t('basicInformation.sectionThree.country')}}</label>
                                <!--<input v-model="student.current_school.country" class="form-control" :class="{'input-blue':student.current_school.country!==null}" :disabled="selectedSchool == 1" id="colegio-pais">-->
                                <select v-model="student.current_school.country_code" class="form-control" :class="{'input-blue':student.current_school.country_code!==null}" id="colegio-pais" :disabled="selectedSchool == 1">
                                <option v-for="(country, index) in countries" :key="index" :value="country.mec_code">{{country.nombre}}</option>
                                </select>
                                <div class="tooltip-error" v-if="v$.student.current_school.country_code.$error"> 
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>
                            </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-3">
                            <div class="form-group">
                                <label for="colegio-ciudad" class="form-required">{{$t('basicInformation.sectionThree.city')}}</label>
                                <input v-model="student.current_school.city" class="form-control" :class="{'input-blue':student.current_school.city!==null}" :disabled="selectedSchool == 1" id="colegio-ciudad">
                            
                            <div class="tooltip-error" v-if="formchecked && v$.student.current_school.city.required.$invalid">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>
                                <div class="tooltip-error" v-if=" formchecked && v$.student.current_school.city.maxLength.$invalid">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                                </div>


                            </div>
                            </div>
                            <!-- <div class="col-12 col-md-6 offset-xl-1 col-xl-3">
                            <div class="form-group">
                                <label for="colegio-direccion" class="form-required">{{$t('basicInformation.sectionThree.address')}}</label>
                                <input v-model="student.current_school.full_address" class="form-control" id="colegio-ciudad" :disabled="selectedSchool == 1">
                            </div>
                            </div> -->
                            <div class="col-12 col-md-6  offset-xl-1 col-xl-7">
                            <div class="form-group">
                                <label for="website" class="form-required">{{$t('basicInformation.sectionThree.website')}}</label>
                                <input v-model="student.current_school.website" class="form-control" :class="{'input-blue':student.current_school.website!==null}" :disabled="selectedSchool == 1" id="website" @blur="checkURL()">
                                
                                <div class="tooltip-error" v-if="formchecked && v$.student.current_school.website.required.$invalid">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>
                                <div class="tooltip-error" v-if=" formchecked && v$.student.current_school.website.maxLength.$invalid">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                                </div>
                                <div class="tooltip-error" v-if="formchecked && v$.student.current_school.website.url.$invalid">
                                <div class="arrow"></div>
                                <span class="tooltip-text">{{$t('errors.fieldURL')}}</span>
                                </div>

                            
                            </div>
                            </div>


                            <div class="w-100"></div>


                            <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                                <div class="form-group">
                                <label for="sistema_academico" class="form-required">{{$t('basicInformation.sectionTwo.academicSystem')}}</label>
                                <select v-model="student.academic_system.id" class="form-control" :class="{'input-blue':student.academic_system.id!==null}" id="sistema-academico" @change="selectAcademicSystem()">
                                    <option v-for="(academic, index) in academicSystem" :key="index" :value="academic.systemstudent_id">{{academic.name}}</option>
                                </select>

                                <div class="tooltip-error" v-if="formchecked && v$.student.academic_system.id.required.$invalid">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>

                                </div>
                            </div>


                            <div class="col-12 col-md-6 col-xl-6" v-if="student.academic_system.id=='7272803a-6f0c-ef11-9f8a-000d3a21364e'">
                                <div class="form-group">
                                    <label for="otro-sistema-academico" class="form-required">{{$t('basicInformation.sectionTwo.anotherAcademicSystem')}}</label>
                                    <input v-model="student.academic_system.other" class="form-control" :class="{'input-blue':student.academic_system.other!==null}" id="otro-sistema-academic">
                                
                                <div class="tooltip-error" v-if="formchecked && v$.student.academic_system.other.required.$invalid">
                                    <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>

                                </div>
                            </div>






                        <h2 class="bloque-info bloque-info-last col-12 text-center">{{$t('basicInformation.sectionFour.title')}}</h2>

                        <div class="container" v-if="!active_past_school_1">
                            <div class="col-12 offset-xl-1">
                            <div class="btn-add text-center-mobile"> 
                                <button @click="addPastSchool1()" type="button" class="btn btn-primary btn-formulario-two col-md-2 col-xl-2"> {{$t('basicInformation.sectionFour.addMore')}}</button>
                            </div> 
                            </div>
                        </div>


                            <div v-if="active_past_school_1" class=" col-12 offset-xl-1 col-xl-10">
                                <div class="container-delete-parent">
                                <a @click="removePastSchool1()" class="img-delete" ><img src="@/assets/img/delete_block_parent.png" alt="" class="float-right"></a>
                                </div>
                            </div> 

                            <div v-if="active_past_school_1" class="col-12 col-md-6 offset-xl-1 col-xl-4">
                                <div class="form-group">
                                <label for="colegio-pasado-1" class="form-required">{{$t('basicInformation.sectionFour.school')}}</label>
                                <div>
                                    <input v-model="student.past_school_1.name" class="form-control" :class="{'input-blue':student.past_school_1.name!==null}" id="colegio-pasado-1" rows="1" autocomplete="off">
                                    <div v-if="loadingSchool1" class="loading-spinner"></div>
                                </div>


                                <div v-if="dropdownSchool1" id="desplegable-colegios-1">
                                    <a v-for="(school, index) in schools" :key="index" @click="selectPastSchool1(school)">
                                    {{school.name}}
                                    </a>
                                </div>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_1.name =='' || student.past_school_1.name === null)">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>

                                </div>
                            </div>

                            <div v-if="active_past_school_1" class="col-12 col-md-6 col-xl-3">
                                <div class="form-group">
                                <label for="colegio-pais-1" class="form-required">{{$t('basicInformation.sectionFour.country')}}</label>
                                <select v-model="student.past_school_1.country_code" class="form-control" :class="{'input-blue':student.past_school_1.country_code!==null}" id="colegio-pais-1" :disabled="selectedSchool1 == 1" @change="selectCountryPast1()">
                                    <option v-for="(country, index) in countries" :key="index" :value="country.mec_code">{{country.nombre}}</option>
                                </select>

                                <div class="tooltip-error" v-if="formchecked && student.past_school_1.country_code === null">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>



                                </div>
                            </div>
                            <div class="w-100"></div>


                            <div v-if="active_past_school_1" class="col-12 col-md-6 offset-xl-1 col-xl-2">
                                <div class="form-group">
                                <label for="colegio-fechadesde-1" class="form-required">{{$t('basicInformation.sectionFour.date0')}}</label>
                                <select v-model="student.past_school_1.date_from_month" class="form-control" :class="{'input-blue':student.past_school_1.date_from_month!==null}" id="colegio-fechadesde-1" >
                                    <option value="null">{{$t('basicInformation.sectionFour.select_month')}}</option>
                                    <option v-for="(month, index) in months" :key="index + 1" :value="index + 1">{{month}}</option>
                                </select>
                                <div class="tooltip-error" v-if=" formchecked && !past_school_validated_date_1">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.dateOrder')}}</span>
                                </div>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_1.date_from_month === null || student.past_school_1.date_from_month == 'null')">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>

                                </div>
                            </div>
                            <div v-if="active_past_school_1" class="col-12 col-md-6 col-xl-2">
                                <div class="form-group">
                                <label for="colegio-fechadesde-1_">&nbsp;</label>
                                <select v-model="student.past_school_1.date_from_year" class="form-control" :class="{'input-blue':student.past_school_1.date_from_year!==null}" id="colegio-fechadesde-1_" >
                                    <option value="null">{{$t('basicInformation.sectionFour.select_year')}}</option>
                                    <option v-for="i in ((currentYear+4) - 1999)" :key="i" :value="i + 1999">{{ i + 1999 }}</option>
                                </select>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_1.date_from_year === null || student.past_school_1.date_from_year == 'null')">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>


                                </div>
                            </div>


                            <div v-if="active_past_school_1" class="col-12 col-md-6 col-xl-2">
                                <div class="form-group">
                                <label for="colegio-fechahasta-1" class="form-required">{{$t('basicInformation.sectionFour.date1')}}</label>
                                <select v-model="student.past_school_1.date_to_month" class="form-control" :class="{'input-blue':student.past_school_1.date_to_month!==null}" id="colegio-fechahasta-1" >
                                    <option value="null">{{$t('basicInformation.sectionFour.select_month')}}</option>
                                    <option v-for="(month, index) in months" :key="index + 1" :value="index + 1">{{month}}</option>
                                </select>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_1.date_to_month === null || student.past_school_1.date_to_month == 'null')">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>


                                </div>
                            </div>
                            <div v-if="active_past_school_1" class="col-12 col-md-6 col-xl-2">
                                <div class="form-group">
                                <label for="colegio-fechahasta-1_">&nbsp;</label>
                                <select v-model="student.past_school_1.date_to_year" class="form-control" :class="{'input-blue':student.past_school_1.date_to_year!==null}" id="colegio-fechahasta-1_" >
                                    <option value="null">{{$t('basicInformation.sectionFour.select_year')}}</option>
                                    <option v-for="i in ((currentYear+4) - 1999)" :key="i" :value="i + 1999">{{ i + 1999 }}</option>
                                </select>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_1.date_to_year === null || student.past_school_1.date_to_year == 'null')">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>


                                </div>
                            </div>

                            <div v-if="active_past_school_1 && !active_past_school_2" class="container">
                                <div class="col-12 offset-xl-1">
                                <div class="btn-add text-center-mobile"> 
                                    <button @click="addPastSchool2()" type="button" class="btn btn-primary btn-formulario-two col-md-2 col-xl-2"> {{$t('basicInformation.sectionFour.addMore')}}</button>
                                </div> 
                                </div>
                            </div>





                            <div v-if="active_past_school_2" class=" col-12 offset-xl-1 col-xl-10">
                                <div class="container-delete-parent">
                                <a @click="removePastSchool2()" class="img-delete" ><img src="@/assets/img/delete_block_parent.png" alt="" class="float-right"></a>
                                </div>
                            </div> 

                            <div v-if="active_past_school_2" class="col-12 col-md-6 offset-xl-1 col-xl-4">
                                <div class="form-group">
                                <label for="colegio-pasado-2" class="form-required">{{$t('basicInformation.sectionFour.school')}}</label>
                                <div>
                                    <input v-model="student.past_school_2.name" class="form-control" :class="{'input-blue':student.past_school_2.name!==null}" id="colegio-pasado-1" rows="1" autocomplete="off">
                                    <div v-if="loadingSchool2" class="loading-spinner"></div>
                                </div>


                                <div v-if="dropdownSchool2" id="desplegable-colegios-1">
                                    <a v-for="(school, index) in schools" :key="index" @click="selectPastSchool2(school)">
                                    {{school.name}}
                                    </a>
                                </div>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_2.name =='' || student.past_school_2.name === null)">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>


                                </div>
                            </div>

                            <div v-if="active_past_school_2" class="col-12 col-md-6 col-xl-3">
                                <div class="form-group">
                                <label for="colegio-pais-2" class="form-required">{{$t('basicInformation.sectionFour.country')}}</label>
                                <select v-model="student.past_school_2.country_code" class="form-control" :class="{'input-blue':student.past_school_2.country_code!==null}" id="colegio-pais-2" :disabled="selectedSchool2 == 1" @change="selectCountryPast2()">
                                    <option v-for="(country, index) in countries" :key="index" :value="country.mec_code">{{country.nombre}}</option>
                                </select>

                                <div class="tooltip-error" v-if="formchecked && student.past_school_2.country_code === null">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>

                                </div>
                            </div>
                            <div class="w-100"></div>

                            <div v-if="active_past_school_2" class="col-12 col-md-6 offset-xl-1 col-xl-2">
                                <div class="form-group">
                                <label for="colegio-fechadesde-2" class="form-required">{{$t('basicInformation.sectionFour.date0')}}</label>
                                <select v-model="student.past_school_2.date_from_month" class="form-control" :class="{'input-blue':student.past_school_2.date_from_month!==null}" id="colegio-fechadesde-2" >
                                    <option value="null">{{$t('basicInformation.sectionFour.select_month')}}</option>
                                    <option v-for="(month, index) in months" :key="index + 1" :value="index + 1">{{month}}</option>
                                </select>
                                <div class="tooltip-error" v-if=" formchecked && !past_school_validated_date_2">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.dateOrder')}}</span>
                                </div>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_2.date_from_month === null || student.past_school_2.date_from_month == 'null')">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>


                                </div>
                            </div>
                            <div v-if="active_past_school_2" class="col-12 col-md-6 col-xl-2">
                                <div class="form-group">
                                <label for="colegio-fechadesde-2_">&nbsp;</label>
                                <select v-model="student.past_school_2.date_from_year" class="form-control" :class="{'input-blue':student.past_school_2.date_from_year!==null}" id="colegio-fechadesde-2_" >
                                    <option value="null">{{$t('basicInformation.sectionFour.select_year')}}</option>
                                    <option v-for="i in ((currentYear+4) - 1999)" :key="i" :value="i + 1999">{{ i + 1999 }}</option>
                                </select>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_2.date_from_year === null || student.past_school_2.date_from_year == 'null')">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>

                                </div>
                            </div>


                            <div v-if="active_past_school_2" class="col-12 col-md-6 col-xl-2">
                                <div class="form-group">
                                <label for="colegio-fechahasta-2" class="form-required">{{$t('basicInformation.sectionFour.date1')}}</label>
                                <select v-model="student.past_school_2.date_to_month" class="form-control" :class="{'input-blue':student.past_school_2.date_to_month!==null}" id="colegio-fechahasta-2" >
                                    <option value="null">{{$t('basicInformation.sectionFour.select_month')}}</option>
                                    <option v-for="(month, index) in months" :key="index + 1" :value="index + 1">{{month}}</option>
                                </select>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_2.date_to_month === null || student.past_school_2.date_to_month == 'null')">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>
                                
                                </div>
                            </div>
                            <div v-if="active_past_school_2" class="col-12 col-md-6 col-xl-2">
                                <div class="form-group">
                                <label for="colegio-fechahasta-2_">&nbsp;</label>
                                <select v-model="student.past_school_2.date_to_year" class="form-control" :class="{'input-blue':student.past_school_2.date_to_year!==null}" id="colegio-fechahasta-2_" >
                                    <option value="null">{{$t('basicInformation.sectionFour.select_year')}}</option>
                                    <option v-for="i in ((currentYear+4) - 1999)" :key="i" :value="i + 1999">{{ i + 1999 }}</option>
                                </select>

                                <div class="tooltip-error" v-if="formchecked && (student.past_school_2.date_to_year === null || student.past_school_2.date_to_year == 'null')">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                </div>

                                </div>
                            </div>

                        



                        <h2 class="bloque-info col-12 text-center"><span v-html="$t('academicResults.sectionOne.pretitle')"></span></h2>
                        <p class="bloque-info col-12 text-center"><span v-html="$t('academicResults.sectionOne.title')"></span></p>


                            <div class="col-12 offset-xl-1 col-md-11 col-xl-8">
                                <p for="" class="label-text">{{$t('academicResults.sectionOne.currentAcademic')}}</p>
                                <div class="form-group file-group">
                                    <input v-model="grades.current_academic_transcripts.name" disabled type="text" id="adjuntar-calificaciones-currente" name="calificaciones" class="form-control col-md-9 col-xl-9" :class="{'input-blue':grades.current_academic_transcripts.name!==null}">
                                    <input @change="loadFile($event, 'current_academic_transcripts')" type="file" class="form-control-file d-none" id="adjuntar-calificaciones-currente-file">
                                    <label for="adjuntar-calificaciones-currente-file" class="btn btn-primary btn-formulario">
                                        {{$t('academicResults.sectionOne.button')}}
                                    </label>
                                    <div class="tooltip-error" v-if="formchecked && v$.grades.current_academic_transcripts.$model.name !== null && v$.grades.current_academic_transcripts.name.extension.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fileExtensionError')}}</span>
                                    </div>

                                    <div class="tooltip-error" v-if="formchecked && v$.grades.current_academic_transcripts.$model.file !== null && v$.grades.current_academic_transcripts.file.file_size_validation.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fileSizeError')}}</span>
                                    </div>


                                </div>
                            </div>


                            <div class="col-12 offset-xl-1 col-md-11 col-xl-8">

                                <div class="form-group">
                                    <label for="grades_scale_current_transcripts_">{{$t('academicResults.sectionOne.mark')}}</label>
                                    <input v-model="student.grades_scale_current_transcripts" class="form-control" :class="{'input-blue':student.grades_scale_current_transcripts!=null && student.grades_scale_current_transcripts!=''}" id="grades_scale_current_transcripts_">
                                </div>

                            </div>


                            <div class="col-12 offset-xl-1 col-md-11 col-xl-8">
                                <p for="" class="form-required label-text">{{$t('academicResults.sectionOne.attachPDF')}}</p>
                                <div class="form-group file-group">
                                    <input v-model="grades.last_term_grades.name" disabled type="text" id="adjuntar-calificaciones-ultimo" name="calificaciones" class="form-control col-md-9 col-xl-9" :class="{'input-blue':grades.last_term_grades.name!==null}">
                                    <input @change="loadFile($event, 'last_term_grades')" type="file" class="form-control-file d-none" id="adjuntar-calificaciones-ultimo-file">
                                    <label for="adjuntar-calificaciones-ultimo-file" class="btn btn-primary btn-formulario">
                                        {{$t('academicResults.sectionOne.button')}}
                                    </label>
                                    <div class="tooltip-error" v-if="formchecked && v$.grades.last_term_grades.$dirty && v$.grades.last_term_grades.name.required.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>
                                    <div class="tooltip-error" v-if="formchecked && v$.grades.last_term_grades.$model.name !== null && v$.grades.last_term_grades.name.extension.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fileExtensionError')}}</span>
                                    </div>
                                    <div class="tooltip-error" v-if="formchecked && v$.grades.last_term_grades.$model.file !== null && v$.grades.last_term_grades.file.file_size_validation.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fileSizeError')}}</span>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12 offset-xl-1 col-md-11 col-xl-8">

                                <div class="form-group">
                                    <label class="form-required" for="grades_scale_transcripts_1_">{{$t('academicResults.sectionOne.mark')}}</label>
                                    <input v-model="student.grades_scale_transcripts_1" class="form-control" :class="{'input-blue':student.grades_scale_transcripts_1!=null&&student.grades_scale_transcripts_1!=''}" id="grades_scale_transcripts_1_">
                                
                                    <div class="tooltip-error" v-if="formchecked && v$.student.grades_scale_transcripts_1.required.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>

                                </div>

                            </div>




                            <div class="col-12 offset-xl-1 col-md-11 col-xl-8">
                                <p for="" class="form-required label-text">{{$t('academicResults.sectionOne.recentGrades')}}</p>
                                <div class="form-group file-group">
                                    <input v-model="grades.current_grades.name" disabled type="text" id="adjuntar-calificaciones" name="calificaciones" class="form-control col-md-9 col-xl-9" :class="{'input-blue':grades.current_grades.name!==null}">
                                    <input @change="loadFile($event, 'current_grades')" type="file" class="form-control-file d-none" id="adjuntar-calificaciones-actual-file">
                                    <label for="adjuntar-calificaciones-actual-file" class="btn btn-primary btn-formulario">
                                        {{$t('academicResults.sectionOne.button')}}
                                    </label>
                                    <div class="tooltip-error" v-if="formchecked && v$.grades.current_grades.name.$error && v$.grades.current_grades.name.required.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>
                                    <div class="tooltip-error" v-if="formchecked && v$.grades.current_grades.$model.name !== null && v$.grades.current_grades.name.extension.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fileExtensionError')}}</span>
                                    </div>
                                    <div class="tooltip-error" v-if="formchecked && v$.grades.current_grades.$model.file !== null && v$.grades.current_grades.file.file_size_validation.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fileSizeError')}}</span>
                                    </div>

                                </div>
                            </div>


                            <div class="col-12 offset-xl-1 col-md-11 col-xl-8">

                                <div class="form-group">
                                    <label class="form-required" for="grades_scale_transcripts_2_">{{$t('academicResults.sectionOne.mark')}}</label>
                                    <input v-model="student.grades_scale_transcripts_2" class="form-control" :class="{'input-blue':student.grades_scale_transcripts_2!=null&&student.grades_scale_transcripts_2!=''}" id="grades_scale_transcripts_2_">
                                
                                    <div class="tooltip-error" v-if="formchecked && v$.student.grades_scale_transcripts_2.required.$invalid">
                                        <div class="arrow"></div>
                                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                                    </div>

                                </div>

                            </div>









              <h2 class="bloque-info col-12 text-center">{{$t('RecomendationsTeamworks.sectionTwo.title')}}</h2>
              <p class="bloque-info col-12 text-center"><span v-html="$t('RecomendationsTeamworks.sectionTwo.subtitle')"></span></p>
              <div class="col-12 offset-xl-1 col-xl-11">
                <div class="text-center-mobile">
                  <p> 
                    <a v-bind:href="this.enviroments.urlDOM+'static/TGC_reference_letter_template.pdf'" target="_blank" class=" btn btn-primary btn-formulario  col-md-5 col-xl-3 btn-width-md-5 btn-left-alone">{{$t('RecomendationsTeamworks.sectionTwo.button')}}</a>
                  </p>
                </div>
                 <p for="" class="label-text">{{$t('RecomendationsTeamworks.sectionTwo.attach')}}</p>

                 <div class="form-group file-group col-12 col-md-11 col-xl-9 force-no-padding-left">
                  <input v-model="document.name" disabled type="text" id="adjuntar-carta2-extra" name="calificaciones" class="form-control col-md-9 col-xl-9" :class="{'input-blue':document.name!==null}">
                  <input @change="loadFile($event, 'document')" type="file" class="form-control-file d-none" id="adjuntar-carta2-extra-presentacion" >
                  <label for="adjuntar-carta2-extra-presentacion" class="btn btn-primary btn-formulario">
                    {{$t('RecomendationsTeamworks.sectionTwo.attachCard')}}
                  </label> 
                  <div class="tooltip-error" v-if="formchecked && this.document.name!==null && v$.document.name.extension.$invalid"> 
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fileExtensionError')}}</span>
                  </div>
                  
                 <div class="tooltip-error" v-if="formchecked && this.document.file!==null && v$.document.file.file_size_validation.$invalid"> 
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fileSizeError')}}</span>
                  </div>

                  </div>
              </div>






















                            




                            <div class="col-12 offset-md-1 col-md-11 offset-md-1 offset-xl-2 col-xl-9">
                                <button @click="backStep()" type="button" class="rounded-pill btn-lg btn-primary mb-2 form-btn-back col-md-5">{{$t('academicResults.buttons.back')}}</button>
                                <button type="submit" class="rounded-pill btn-lg btn-primary mb-2 form-btn-submit offset-md-1 offset-xl-1 col-md-5">{{$t('academicResults.buttons.save')}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <ModalExpireToken v-if="expireToken" />
        <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" :literal="serviceErrorMsg" />
        <ModalWorking v-if="showModalWorking" :show-modal-working="showModalWorking" />
        <Footer />
    </div>
</template>

<script>

    import { mapState } from 'vuex'
    import ModalExpireToken from '@/components/ModalExpireToken.vue'
    import ModalServiceError from '@/components/ModalServiceError.vue'
    import ModalWorking from '@/components/ModalWorking.vue'
    import HeaderBar from '@/components/HeaderBar.vue'
    import Header from '@/components/Header.vue'
    import Steps from '@/components/Steps.vue'
    import Footer from '@/components/Footer.vue'
    import useVuelidate from '@vuelidate/core'
    import { required, maxLength, requiredIf, url } from "@vuelidate/validators"
    import moment from 'moment'
    import shared from '@/shared'


    const extension = (value) => {
        if (value)
        {
            var re = /(\.jpg|\.pdf)$/i;
            return (re.exec(value)) ? true : false;
        }
        else{
            return true;
        }
    }

    const file_size_validation = (value) =>  {
        if (value==null) {
            return true;
        }
        else if (!value) {
            return false;
        }
        let file = value;
        return (file.length < 10485760 && file.length > 0);
    };


    // const requiredIf = () => {
    //   return (!this.native_or_bilingual && !this.currently_studying_english) ? true : false;
    // }

    export default {
        name: 'AcademicResults',
        components: {
            HeaderBar,
            Header,
            Steps,
            Footer,
            ModalExpireToken,
            ModalServiceError,
            ModalWorking
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {
                student:
                {
                    current_school: {
                        name: null,
                        id: null,
                        country: null,
                        country_code: null,
                        city: null,
                        website: null,
                        validated: null
                    },
                    academic_system:{
                        academic_name:null,
                        id: null,
                        other: null
                    },
                    past_school_1: {
                        name: null,
                        id: null,
                        country: null,
                        country_code: null,
                        date_from_month: null,
                        date_from_year: null,
                        date_to_month: null,
                        date_to_year: null,
                        validated: null
                    },
                    past_school_2: {
                        name: null,
                        id: null,
                        country: null,
                        country_code: null,
                        date_from_month: null,
                        date_from_year: null,
                        date_to_month: null,
                        date_to_year: null,
                        validated: null
                    },
                    grades_scale_current_transcripts: null,
                    grades_scale_transcripts_1: null,
                    grades_scale_transcripts_2: null
                },
                grades: {
                    current_academic_transcripts:{
                        name: null,
                        file: null,
                        delete: false
                    },
                    last_term_grades: {
                        name: null,
                        file: null,
                        delete: false
                    },
                    current_grades: {
                        name: null,
                        file: null,
                        delete: false
                    },
                },
                document: {
                    name: null,
                    file: null,
                    delete : false
                },                
                expireToken: false,
                serviceError: false,
                serviceErrorMsg: null,
                showModalWorking: true,
                formchecked: false,
                schools: [],
                countries: [],
                months: moment.months(),
                currentYear: new Date().getFullYear(),
                active_past_school_1:false,
                active_past_school_2:false,
                past_school_validated_1: false,
                past_school_validated_2: false,
                past_school_validated_date_1: false,
                past_school_validated_date_2: false,
                loadingSchool: false,
                dropdownSchool: false,
                selectedSchool: false,
                loadingSchool1: false,
                dropdownSchool1: false,
                selectedSchool1: false,
                loadingSchool2: false,
                dropdownSchool2: false,
                selectedSchool2: false,
                academicSystem: [],
                loadingAcademicSystem: false,
                dropdownAcademicSystem: false
            }
        },
        validations() {

                return {
                    student:
                    {
                        current_school: {
                        name: { required, maxLength: maxLength(100) },
                        country_code: { required },
                        city: { required, maxLength: maxLength(100) },
                        website: { required, maxLength: maxLength(100),url }
                        },
                        academic_system:{
                            id: { required },
                            other: { required: requiredIf(this.student.academic_system.id=='7272803a-6f0c-ef11-9f8a-000d3a21364e') }
                        },
                        grades_scale_transcripts_1: { required },
                        grades_scale_transcripts_2: { required }
                    },
                    grades: {
                        current_academic_transcripts: {
                            name: {
                                extension
                            },
                            file:{
                                file_size_validation
                            }

                        },
                        last_term_grades: {
                            name: {
                                required,
                                extension
                            },
                            file:{
                                file_size_validation
                            }
                        },
                        current_grades: {
                            name: {
                                required,
                                extension
                            },
                            file:{
                                file_size_validation
                            }
                        },
                    },
                    document:{
                         name: {
                                extension
                            },
                            file:{
                                file_size_validation
                            }

                    }
                    
                }

        },
        computed: {
            ...mapState([
                'step',
                'msalConfig',
                'enviroments',
                'AcademicResults',
                'idAdmission'
            ]),
            nameSchool() {
                return this.student.current_school.name;
            },
            nameSchool1() {
            return this.student.past_school_1.name;
            },
            nameSchool2() {
            return this.student.past_school_2.name;
            },
            account() {
                return Object.keys(this.msalConfig.account).length !== 0 ? this.msalConfig.account : undefined;
            },
            accessToken() {
                return Object.keys(this.msalConfig.accessToken).length !== 0 ? this.msalConfig.accessToken : undefined;
            }
        },
        watch:
        {
            nameSchool(newValue) {
                if(this.dropdownSchool) {
                this.dropdownSchool = true;
                this.getSchools(newValue,0);
                } else {
                this.schools = [];
                }
                if(newValue !== '') this.dropdownSchool = true;
            },
            nameSchool1(newValue1) {
                if(this.dropdownSchool1) {
                    this.dropdownSchool1 = true;
                    this.getSchools(newValue1,1);
                } else {
                    this.schools = [];
                }
                if(newValue1 !== '') 
                    this.dropdownSchool1 = true;
            },
            nameSchool2(newValue2) {
                if(this.dropdownSchool2) {
                    this.dropdownSchool2 = true;
                    this.getSchools(newValue2,2);
                } else {
                    this.schools = [];
                }
                if(newValue2 !== '') 
                    this.dropdownSchool2 = true;
            },

            "student.academic_system.id"()
            {
                if (this.student.academic_system.id!='7272803a-6f0c-ef11-9f8a-000d3a21364e')
                {this.student.academic_system.other=''}
            },
            
        },
        mounted() {

            this.normalize_name = shared.normalize_name;

            window.scrollTo(0, 0);

            this.getCountries();
            this.getAcademicResults();
            this.getAcademicSystem();

            this.$store.watch(() => {
                return this.$store.state.AcademicResults.grades
            }, (res) => {
                if ((res === undefined) || (res === null)) return;
                const { current_academic_transcripts, last_term_grades, current_grades } = res
                this.grades.current_academic_transcripts.name = current_academic_transcripts.name
                this.grades.last_term_grades.name = last_term_grades.name
                this.grades.current_grades.name = current_grades.name
            })


            this.$store.watch(() => {
                return this.$store.state.AcademicResults.document
                },(res) => {
                if((res === undefined)||(res === null)) return;

                this.document = res;

                this.document.delete = "false";
                this.document.file = "null";
                })


        },
        methods: {

            getAcademicResults() {
                this.axios.get(`${this.enviroments.urlDev}/AcademicResult?token=${this.accessToken}&id_admision=${this.idAdmission}`).then(response => {

                    this.showModalWorking = false;
                    document.body.style.overflowY = "auto";

                    if (response.data.status === 1) {
                        this.serviceError = true;
                        this.serviceErrorMsg = response.data.message;
                    } else if (response.data.status === 2) {
                        this.expireToken = true;
                    }
                    else if(response.data.status === 0){


                        if (response.data.document === null)
                        { response.data.document = {'name': null,'file': null,'delete' : false}}

                        this.$store.commit('AcademicResults/setDocument', response.data.document)


                        if (response.data.student.current_school === null)
                        { response.data.student.current_school = {'name': null,'id': null,'country': null,'country_code': null,'city': null,'website': null,'validated': null};}

                        if (response.data.student.past_school_1 === null)
                        { response.data.student.past_school_1 = {'name': null,'id': null,'country': null,'country_code': null,'date_from_month': null,'date_from_year': null,'date_to_month': null,'date_to_year': null,'validated': true};}

                        if (response.data.student.past_school_1.date_from_month == "")
                        response.data.student.past_school_1.date_from_month = null;
                        if (response.data.student.past_school_1.date_from_year == "")
                        response.data.student.past_school_1.date_from_year = null;
                        if (response.data.student.past_school_1.date_to_month == "")
                        response.data.student.past_school_1.date_to_month = null;
                        if (response.data.student.past_school_1.date_to_year == "")
                        response.data.student.past_school_1.date_to_year = null;
                        if (response.data.student.past_school_2.date_from_month == "")
                        response.data.student.past_school_2.date_from_month = null;
                        if (response.data.student.past_school_2.date_from_year == "")
                        response.data.student.past_school_2.date_from_year = null;
                        if (response.data.student.past_school_2.date_to_month == "")
                        response.data.student.past_school_2.date_to_month = null;
                        if (response.data.student.past_school_2.date_to_year == "")
                        response.data.student.past_school_2.date_to_year = null;

                        if (response.data.student.past_school_2 === null)
                        { response.data.student.past_school_2 = {'name': null,'id': null,'country': null,'country_code': null,'date_from_month': null,'date_from_year': null,'date_to_month': null,'date_to_year': null,'validated': true};}


                        if (response.data.student.current_school.validated == true) {this.selectedSchool=true}
                        if (response.data.student.past_school_1.validated == true) {this.selectedSchool1=true}
                        if (response.data.student.past_school_2.validated == true) {this.selectedSchool2=true}

                        if (response.data.student.past_school_1.name != "" && response.data.student.past_school_1.name != null ) {this.active_past_school_1=true}
                        if (response.data.student.past_school_2.name != "" && response.data.student.past_school_2.name != null) {this.active_past_school_2=true}

                        if (!response.data.student.academic_system || response.data.student.academic_system === null) { response.data.student.academic_system = {'id': null,'other': null};}

                        this.student = response.data.student;

                        if (response.data.grades.current_academic_transcripts === null) { response.data.grades.current_academic_transcripts = { 'name': null, 'file': null, 'delete': false }; }

                        if (response.data.grades.last_term_grades === null) { response.data.grades.last_term_grades = { 'name': null, 'file': null, 'delete': false }; }

                        if (response.data.grades.current_grades === null) { response.data.grades.current_grades = { 'name': null, 'file': null, 'delete': false }; }

                        this.$store.commit('AcademicResults/setGrades', response.data.grades)
    
                    }


                })
                    .catch(function (error) {
                        console.log(error);
                        this.serviceError = true;
                        this.serviceErrorMsg = `"ERROR:: ACADEMIC RESULTS get Academic Results: ${error}`;
                    });
            },
            setAcademicResults() {


                if (this.grades.current_academic_transcripts.name != null && this.grades.current_academic_transcripts.name != '')
                { this.grades.current_academic_transcripts.name = this.normalize_name(this.grades.current_academic_transcripts.name)}

                if (this.grades.last_term_grades.name != null && this.grades.last_term_grades.name != '')
                { this.grades.last_term_grades.name = this.normalize_name(this.grades.last_term_grades.name)}

                if (this.grades.current_grades.name != null && this.grades.current_grades.name != '')
                { this.grades.current_grades.name = this.normalize_name(this.grades.current_grades.name)}

                if (this.document.name != null && this.document.name != '')
                { this.document.name = this.normalize_name(this.document.name)}



                this.showModalWorking = true;
                document.body.style.overflowY = "hidden";
                this.axios.post(`${this.enviroments.urlDev}/AcademicResult?token=${this.accessToken}&id_admision=${this.idAdmission}`, {
                    student: this.student,
                    grades: this.grades,
                    document: this.document,
                }).then(response => {

                    this.showModalWorking = false;

                    if (response.data.status === 1) {
                        this.serviceError = true;
                        this.serviceErrorMsg = response.data.message;
                    } else if (response.data.status === 2) {
                        this.expireToken = true;
                    } else if (response.data.status === 0) {
                        this.$router.push('video-presentation');
                        this.step.firstStep = false;
                        this.step.step2Completed = true;
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        this.serviceError = true;
                        this.serviceErrorMsg = `"ERROR:: ACADEMIC RESULTS set Academic Results: ${error}`;

                    });
            },
            loadFile(e, param) {

                let file = e.target.files[0]
                let reader = new FileReader()

                reader.onload = (e) => {
                    if (param === 'current_academic_transcripts') {
                        this.grades.current_academic_transcripts = {
                            name: file.name,
                            delete: false,
                            file: e.target.result.replace("data:image/jpeg;base64,", "").replace("data:application/pdf;base64,", "")
                        }
                    }
                    if (param === 'last_term_grades') {
                        this.grades.last_term_grades = {
                            name: file.name,
                            delete: false,
                            file: e.target.result.replace("data:image/jpeg;base64,", "").replace("data:application/pdf;base64,", "")
                        }
                    }
                    if (param === 'current_grades') {
                        this.grades.current_grades = {
                            name: file.name,
                            delete: false,
                            file: e.target.result.replace("data:image/jpeg;base64,", "").replace("data:application/pdf;base64,", "")
                        }
                    }
                    if(param === 'document') {
                    this.document = {
                        name: file.name,
                        delete: false,
                        file: e.target.result.replace("data:image/jpeg;base64,","").replace("data:application/pdf;base64,","")
                    }
                    }

                }

                reader.readAsDataURL(file);
            },
            scroll2error() {
                setTimeout(function () { if (document.getElementsByClassName('tooltip-error').length != 0) { document.getElementsByClassName('tooltip-error')[0].parentElement.scrollIntoView(); } }.bind(this), 100)
            },
            saveAndContinue() {
                this.v$.$touch();

                this.formchecked = true;
                let extra_errors = false;

                this.past_school_validated_1 = true;
                this.past_school_validated_2 = true;
                this.past_school_validated_date_1 = true;
                this.past_school_validated_date_2 = true;


                if (this.active_past_school_1)
                {
                    if ((this.student.past_school_1.date_from_year > this.student.past_school_1.date_to_year) || 
                    ((this.student.past_school_1.date_from_year == this.student.past_school_1.date_to_year) && (this.student.past_school_1.date_from_month > this.student.past_school_1.date_to_month))
                    )
                    { 
                        this.past_school_validated_1 = false;
                        this.past_school_validated_date_1 = false;
                    }
                    
                    if (this.student.past_school_1.name =='' || this.student.past_school_1.name === null || this.student.past_school_1.country_code === null || this.student.past_school_1.date_to_year === null || this.student.past_school_1.date_to_year == 'null' || this.student.past_school_1.date_to_month === null || this.student.past_school_1.date_to_month == 'null' || this.student.past_school_1.date_from_year === null || this.student.past_school_1.date_from_year == 'null' || this.student.past_school_1.date_from_month === null || this.student.past_school_1.date_from_month == 'null')
                    { this.past_school_validated_1 = false;}

                }

                if (this.active_past_school_2)
                {
                    if ((this.student.past_school_2.date_from_year > this.student.past_school_2.date_to_year) || 
                    ((this.student.past_school_2.date_from_year == this.student.past_school_2.date_to_year) && (this.student.past_school_2.date_from_month > this.student.past_school_2.date_to_month))
                    )
                    { 
                        this.past_school_validated_2 = false;
                        this.past_school_validated_date_2 = false;
                    }

                    if (this.student.past_school_2.name =='' || this.student.past_school_2.name === null || this.student.past_school_2.country_code === null || this.student.past_school_2.date_to_year === null || this.student.past_school_2.date_to_year == 'null' || this.student.past_school_2.date_to_month === null || this.student.past_school_2.date_to_month == 'null' || this.student.past_school_2.date_from_year === null || this.student.past_school_2.date_from_year == 'null' || this.student.past_school_2.date_from_month === null || this.student.past_school_2.date_from_month == 'null')
                    { this.past_school_validated_2 = false;}

                }

                if( !this.past_school_validated_1 || !this.past_school_validated_2) return;


                if (this.v$.student.$error || this.v$.grades.$error || this.v$.document.$error || extra_errors) return
                this.setAcademicResults();
            },
            modalErrorClose() { this.serviceError = false },
            backStep() {
                this.$router.push('basic-information');
                this.step.firstStep = false;
            },


        getSchools(value,pred) {

        this.schools = [];
        if (pred == 0 )
        {
        this.loadingSchool = true;
        this.selectedSchool = false;
        this.student.current_school.id = null;
        }
        else if (pred == 1 )
        {
        this.loadingSchool1 = true;
        this.selectedSchool1 = false;
        this.student.past_school_1.id = null;
        this.student.past_school_1.validated = null;
        }
        else if (pred == 2 )
        {
        this.loadingSchool2 = true;
        this.selectedSchool2 = false;
        this.student.past_school_2.id = null;
        this.student.past_school_2.validated = null;
        }  

        this.axios.get(`${this.enviroments.urlDev}/School?token=${this.accessToken}&cadena=${value}`).then(response => {
          if (response.data.status === 1) {
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.expireToken = true;
          }
          else if(response.data.status === 0){
            this.schools = response.data.school_matches;

            if ( pred == 0 ) 
            {
              this.loadingSchool = false;
              this.dropdownSchool1 = false;
              this.dropdownSchool2 = false;
            }
            if ( pred == 1 ) 
            {
              this.loadingSchool1 = false;
              this.dropdownSchool = false;
              this.dropdownSchool2 = false;
            }
            if ( pred == 2 ) 
            {
              this.loadingSchool2 = false;
              this.dropdownSchool = false;
              this.dropdownSchool1 = false;
            }

          }
        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Schools: ${error}`;
        });

      },
      selectSchool(school) {
        this.loadingSchool = false;
        this.dropdownSchool = false;
        this.selectedSchool = true;

        this.student.current_school = {
          name: school.name,
          id: school.school_id,
          country: school.country,
          country_code: school.country_mec_code,
          city: school.city,
          website: school.website
          //full_address: school.fulladdress
        }
      },
      selectCountryPast1() {
        console.log(this.student.past_school_1.country_code);
        let exists = this.countries.find(p => p.mec_code == this.student.past_school_1.country_code)
        if(exists !== -1) { 
          this.student.past_school_1.country = exists.nombre;
        }
      },
      selectCountryPast2() {
        let exists = this.countries.find(p => p.mec_code == this.student.past_school_2.country_code)
        if(exists !== -1) { 
          this.student.past_school_2.country = exists.nombre;
        }
      },
      selectPastSchool1(school) {

        this.loadingSchool1 = false;
        this.dropdownSchool1 = false;
        this.selectedSchool1 = true;

        this.student.past_school_1.name = school.name;
        this.student.past_school_1.id = school.school_id;
        this.student.past_school_1.country = school.country;
        this.student.past_school_1.country_code = school.country_mec_code;
        this.student.past_school_1.validated = true;

      },
      selectPastSchool2(school) {
        this.loadingSchool2 = false;
        this.dropdownSchool2 = false;
        this.selectedSchool2 = true;

        this.student.past_school_2.name = school.name;
        this.student.past_school_2.id = school.school_id;
        this.student.past_school_2.country = school.country;
        this.student.past_school_2.country_code = school.country_mec_code;
        this.student.past_school_2.validated = true;
      },
      addPastSchool1() {
        this.active_past_school_1= true;
      },
      removePastSchool1() {
        this.active_past_school_1= false;
        this.student.past_school_1 = {'name': null,'id': null,'country': null,'country_code': null,'date_from_month': null,'date_from_year': null,'date_to_month': null,'date_to_year': null,'validated': true};
      },
      addPastSchool2() {
        this.active_past_school_2= true;
      },
      removePastSchool2() {
        this.active_past_school_2= false;
        this.student.past_school_2 = {'name': null,'id': null,'country': null,'country_code': null,'date_from_month': null,'date_from_year': null,'date_to_month': null,'date_to_year': null,'validated': true};
      },

      getAcademicSystem() {
        this.academicSystem = [];
  //      this.loadingAcademicSystem = true;
        this.axios.get(`${this.enviroments.urlDev}/AcademicSystem?isFromOnlineApplication=true&token=${this.accessToken}`).then(response => {

          if (response.data.status === 1) {
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.expireToken = true;
          }
          else if(response.data.status === 0){

            this.academicSystem = response.data.system_students;
  //          this.loadingAcademicSystem = false;

          }
        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Academic Systems: ${error}`;
        });
      },
      selectAcademic(event) {
              console.log(event.target.value);
      },
      selectAcademicSystem(academic) {
        this.loadingAcademicSystem = false;
        this.dropdownAcademicSystem = false;
        this.student.academic_system = {
          academic_name: academic.name, 
          id: academic.systemstudent_id
        }
      },
    getCountries() {

        let langParam = "";
        if (this.$i18n.locale == 'es')
        {langParam = "&idioma=es";}

        this.axios.get(`${this.enviroments.urlDev}/Country?token=${this.accessToken}${langParam}`).then(response => {
          if (response.data.status === 1) {
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.expireToken = true;
          }
          else if(response.data.status === 0){
            this.countries = response.data.countries_matches;
          }
        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Countries: ${error}`;
        });
      },

    checkURL() {
                if (this.student.current_school.website.search('http://')==-1 && this.student.current_school.website.search('https://')==-1)
                {this.student.current_school.website = 'https://'+this.student.current_school.website}
    }




        }
    }
</script>
